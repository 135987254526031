<template>
  <b-overlay :show="loading">
    <section class="mt-5">
      <iframe id="viewer" class="w-100" :src="pdf" />
    </section>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { BOverlay } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "StatementDetail",
  components: {
    BOverlay,
  },
  data() {
    return {
      loading: false,
      pdf: '',
    }
  },
  created(){
    this.fetchLoanStatement();
  },
  methods: {
    async fetchLoanStatement(){
      try {
        this.loading = true

        const { loan_id } = this.$route.params;
        const response = await this.useJwt().clientService.fetchLoanStatement(loan_id);
        const { pdf } = response.data.data;
        this.pdf = pdf
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style  lang="scss">

  iframe {
    height: 100vh;
  }
</style>
